import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PRPQuestions.css';
import logo from '../../../images/logo5.png';

const HairLossTime = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // State to handle animation visibility

  useEffect(() => {
    setIsVisible(true); // Trigger the fade-in animation on mount
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleContinue = () => {
    if (!selectedOption) {
      alert('Please select an option before continuing.');
      return;
    }
    navigate('/prp-hair-expectations');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
              <img src={logo} alt="Logo" className="logo2" />
      <h1>When did you start noticing changes to your hair?</h1>
      <div
        className={`option ${selectedOption === 'Over a year ago' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Over a year ago')}
      >
        Over a year ago
      </div>
      <div
        className={`option ${selectedOption === 'Past year' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Past year')}
      >
        In the past year
      </div>
      <div
        className={`option ${selectedOption === 'Past few months' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Past few months')}
      >
        In the past few months
      </div>
      <div
        className={`option ${selectedOption === 'Not sure' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Not sure')}
      >
        Not sure
      </div>
      <button className="button" onClick={handleContinue}>Continue</button>
    </div>
  );
};

export default HairLossTime;
