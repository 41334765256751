import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Questions/Therapy.css';
import logo from '../../images/logo5.png';

const TherapyIntroduction = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/therapy-primary-concern');
    }, 4000); // Auto-redirect after 5 seconds

    return () => clearTimeout(timer);
  }, [navigate]);


  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
                 <img src={logo} alt="Logo" className="logo2" />
      <p className="fade-in-text">
        Mental health matters. <br/>
        <br/>Let’s understand your concerns to match you with the best therapist for your needs.
      </p>
    </div>
  );
};

export default TherapyIntroduction;
