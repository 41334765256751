import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Weight.css';
import logo from '../../images/logo5.png';

const WeightLossCTA = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleNext = () => {
    navigate('/enquiry');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
        <img src={logo} alt="Logo" className="logo2" />
      <p className="fade-in-text">
        Your responses suggest you’re a good candidate for our weight loss programme. Book your initial consultation to create your bespoke plan today.
      </p>
      <button className="button" onClick={handleNext}>
        Book Now
      </button>
    </div>
  );
};

export default WeightLossCTA;
