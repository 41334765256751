import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Weight.css';
import logo from '../../images/logo5.png';

const WeightLossDietaryHabits = () => {
  const navigate = useNavigate();
  const [habits, setHabits] = useState('');
  const [restrictions, setRestrictions] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleContinue = () => {
    if (!habits) {
      alert('Please describe your dietary habits.');
      return;
    }
    navigate('/weight-loss-exercise-routine');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
        <img src={logo} alt="Logo" className="logo2" />
      <h1>Describe your current eating habits</h1>
      <textarea
        placeholder="e.g., high-carb, low-calorie"
        value={habits}
        onChange={(e) => setHabits(e.target.value)}
      />
      <h1>Do you have any known food intolerances or dietary restrictions?</h1>
      <textarea
        placeholder="e.g., lactose intolerance, gluten-free"
        value={restrictions}
        onChange={(e) => setRestrictions(e.target.value)}
      />
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default WeightLossDietaryHabits;
