import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PRPQuestions.css';
import logo from '../../../images/logo5.png';

const HairGoals = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // State to handle animation visibility

  useEffect(() => {
    setIsVisible(true); // Trigger the fade-in animation on mount
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleContinue = () => {
    if (!selectedOption) {
      alert('Please select an option before continuing.');
      return;
    }
    navigate('/prp-hair-changes');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
      <img src={logo} alt="Logo" className="logo2" />
      <h1>Which best represents your hair loss and goals?</h1>
      <div
        className={`option ${selectedOption === 'Receding hairline' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Receding hairline')}
      >
        Receding hairline, want to slow its progress
      </div>
      <div
        className={`option ${selectedOption === 'Exploring options' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Exploring options')}
      >
        Experiencing hair loss, exploring options
      </div>
      <div
        className={`option ${selectedOption === 'Ready for treatment' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Ready for treatment')}
      >
        Experiencing hair loss, ready to start treatment ASAP
      </div>
      <div
        className={`option ${selectedOption === 'Get ahead' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Get ahead')}
      >
        No hair loss yet, want to get ahead of it
      </div>
      <button className="button" onClick={handleContinue}>Continue</button>
    </div>
  );
};

export default HairGoals;
