import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Therapy.css';
import logo from '../../../images/logo5.png';

const TherapyPreviousTherapy = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [outcome, setOutcome] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleContinue = () => {
    if (!selectedOption) {
      alert('Please select an option before continuing.');
      return;
    }
    if (selectedOption === 'Yes' && !outcome) {
      alert('Please describe the outcome.');
      return;
    }
    navigate('/therapy-cta');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
                       <img src={logo} alt="Logo" className="logo2" />
      <h1>Have you sought therapy or taken medication for this before?</h1>
      <div
        className={`option ${selectedOption === 'Yes' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Yes')}
      >
        Yes
      </div>
      <div
        className={`option ${selectedOption === 'No' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('No')}
      >
        No
      </div>
      {selectedOption === 'Yes' && (
        <textarea
          value={outcome}
          onChange={(e) => setOutcome(e.target.value)}
          placeholder="What was the outcome?"
        />
      )}
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default TherapyPreviousTherapy;
