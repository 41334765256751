import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './GP.css';
import logo from '../../images/logo5.png';

const GPMedicalHistory = () => {
  const navigate = useNavigate();
  const [hasOngoingConditions, setHasOngoingConditions] = useState(null);
  const [ongoingConditions, setOngoingConditions] = useState('');
  const [isTakingMedications, setIsTakingMedications] = useState(null);
  const [medications, setMedications] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleContinue = () => {
    if (hasOngoingConditions === null || isTakingMedications === null) {
      alert('Please answer all questions before continuing.');
      return;
    }
    navigate('/gp-lifestyle-factors');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
         <img src={logo} alt="Logo" className="logo2" />
      <h1>Do you have any other ongoing conditions?</h1>
      <div
        className={`option ${hasOngoingConditions === 'Yes' ? 'selected' : ''}`}
        onClick={() => setHasOngoingConditions('Yes')}
      >
        Yes
      </div>
      <div
        className={`option ${hasOngoingConditions === 'No' ? 'selected' : ''}`}
        onClick={() => setHasOngoingConditions('No')}
      >
        No
      </div>
      {hasOngoingConditions === 'Yes' && (
        <textarea
          placeholder="Please specify your conditions"
          value={ongoingConditions}
          onChange={(e) => setOngoingConditions(e.target.value)}
        />
      )}
      <h1>Are you taking any medications?</h1>
      <div
        className={`option ${isTakingMedications === 'Yes' ? 'selected' : ''}`}
        onClick={() => setIsTakingMedications('Yes')}
      >
        Yes
      </div>
      <div
        className={`option ${isTakingMedications === 'No' ? 'selected' : ''}`}
        onClick={() => setIsTakingMedications('No')}
      >
        No
      </div>
      {isTakingMedications === 'Yes' && (
        <textarea
          placeholder="Please list your medications or upload a photo of your prescription"
          value={medications}
          onChange={(e) => setMedications(e.target.value)}
        />
      )}
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default GPMedicalHistory;
