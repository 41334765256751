import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './GP.css';
import logo from '../../images/logo5.png';

const GPChronicCondition = () => {
  const navigate = useNavigate();
  const [conditions, setConditions] = useState([]);
  const [otherCondition, setOtherCondition] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleOptionClick = (condition) => {
    setConditions((prevConditions) =>
      prevConditions.includes(condition)
        ? prevConditions.filter((c) => c !== condition)
        : [...prevConditions, condition]
    );
  };

  const handleContinue = () => {
    if (!conditions.length) {
      alert('Please select at least one condition.');
      return;
    }
    navigate('/gp-medical-history');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
         <img src={logo} alt="Logo" className="logo2" />
      <h1>Which condition(s) do you need reviewed?</h1>
      {['Hypertension', 'Diabetes', 'Asthma', 'Other'].map((condition) => (
        <div
          key={condition}
          className={`option ${conditions.includes(condition) ? 'selected' : ''}`}
          onClick={() => handleOptionClick(condition)}
        >
          {condition}
        </div>
      ))}
      {conditions.includes('Other') && (
        <textarea
          placeholder="Please specify"
          value={otherCondition}
          onChange={(e) => setOtherCondition(e.target.value)}
        />
      )}
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default GPChronicCondition;
