import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PRPQuestions.css';
import logo from '../../../images/logo5.png';

const HairLossAmount = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // State to handle animation visibility

  useEffect(() => {
    setIsVisible(true); // Trigger the fade-in animation on mount
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleContinue = () => {
    if (!selectedOption) {
      alert('Please select an option before continuing.');
      return;
    }
    navigate('/prp-hair-loss-time');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
              <img src={logo} alt="Logo" className="logo2" />
      <h1>How much hair have you lost?</h1>
      <div
        className={`option ${selectedOption === 'A lot' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('A lot')}
      >
        A lot (It’s obvious to everyone)
      </div>
      <div
        className={`option ${selectedOption === 'Some' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Some')}
      >
        Some (Those close to me notice)
      </div>
      <div
        className={`option ${selectedOption === 'A little' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('A little')}
      >
        A little (Only I notice)
      </div>
      <button className="button" onClick={handleContinue}>Continue</button>
    </div>
  );
};

export default HairLossAmount;
