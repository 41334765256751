import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './BloodTests.css';
import logo from '../../images/logo5.png';

const BloodTestsReason = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [otherReason, setOtherReason] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setOtherReason('');
  };

  const handleContinue = () => {
    if (!selectedOption) {
      alert('Please select a reason for your blood test.');
      return;
    }
    if (selectedOption === 'Other' && !otherReason) {
      alert('Please specify your reason for the blood test.');
      return;
    }
    navigate('/blood-tests-symptoms');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
        <img src={logo} alt="Logo" className="logo2" />
      <h1>What is the main reason you’re seeking a blood test?</h1>
      <div
        className={`option ${selectedOption === 'General health check' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('General health check')}
      >
        General health check
      </div>
      <div
        className={`option ${selectedOption === 'Fatigue' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Fatigue')}
      >
        Fatigue
      </div>
      <div
        className={`option ${selectedOption === 'Hormone concerns' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Hormone concerns')}
      >
        Hormone concerns
      </div>
      <div
        className={`option ${selectedOption === 'Vitamin deficiency' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Vitamin deficiency')}
      >
        Vitamin deficiency
      </div>
      <div
        className={`option ${selectedOption === 'Other' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Other')}
      >
        Other
      </div>
      {selectedOption === 'Other' && (
        <textarea
          placeholder="Please specify"
          value={otherReason}
          onChange={(e) => setOtherReason(e.target.value)}
        />
      )}
            <div className="disclaimer">
        The quiz is not a medical assessment and your responses will not be shared with a medical provider. The Wellness uses your responses to personalize your experience and for other uses as described in our <a href="/privacy-policy" className="privacy-link">Privacy Policy</a>.
      </div>
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default BloodTestsReason;
