// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999; /* Ensure it stays on top of other elements */
    background-color: #25D366; /* WhatsApp green */
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.15);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .whatsapp-button:hover {
    background-color: #1ebd5c;
  }
  
  .whatsapp-icon {
    width: 35px;
    height: 35px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/WhatsAppButton.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa,EAAE,6CAA6C;IAC5D,yBAAyB,EAAE,mBAAmB;IAC9C,kBAAkB;IAClB,aAAa;IACb,uCAAuC;IACvC,qBAAqB;IACrB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":[".whatsapp-button {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    z-index: 9999; /* Ensure it stays on top of other elements */\n    background-color: #25D366; /* WhatsApp green */\n    border-radius: 50%;\n    padding: 10px;\n    box-shadow: 0 2px 10px rgba(0,0,0,0.15);\n    text-decoration: none;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .whatsapp-button:hover {\n    background-color: #1ebd5c;\n  }\n  \n  .whatsapp-icon {\n    width: 35px;\n    height: 35px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
