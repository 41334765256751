// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homepage {
  position: relative;
  z-index: 1;
  opacity: 1;
  transform: translateY(0);
}

/* Remove loader and transition overlays */
.loader,
.transition,
.transition.lower,
.transition.upper {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;EACV,wBAAwB;AAC1B;;AAEA,0CAA0C;AAC1C;;;;EAIE,aAAa;AACf","sourcesContent":[".homepage {\n  position: relative;\n  z-index: 1;\n  opacity: 1;\n  transform: translateY(0);\n}\n\n/* Remove loader and transition overlays */\n.loader,\n.transition,\n.transition.lower,\n.transition.upper {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
