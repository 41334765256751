import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logo5.png';
import './PRPLoading.css';

const PRPLoading = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/prp-options');
    }, 4000); // Auto-redirect after 5 seconds

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
      <p className="fade-in-text">
      Thank you for considering PRP therapy at The Wellness. <br/>
      <br/>
      Let’s gather some information to see if PRP is right for you.
      </p>
    </div>
  );
};

export default PRPLoading;
