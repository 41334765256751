import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Therapy.css';
import logo from '../../../images/logo5.png';

const TherapyPrimaryConcern = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleContinue = () => {
    if (!selectedOption) {
      alert('Please select an option before continuing.');
      return;
    }
    navigate('/therapy-duration-symptoms');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
                       <img src={logo} alt="Logo" className="logo2" />
      <h1>What brings you to therapy?</h1>
      <div
        className={`option ${selectedOption === 'Anxiety' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Anxiety')}
      >
        Anxiety
      </div>
      <div
        className={`option ${selectedOption === 'Depression' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Depression')}
      >
        Depression
      </div>
      <div
        className={`option ${selectedOption === 'Stress' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Stress')}
      >
        Stress
      </div>
      <div
        className={`option ${selectedOption === 'Trauma' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Trauma')}
      >
        Trauma
      </div>
      <div
        className={`option ${selectedOption === 'Other' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Other')}
      >
        Other
      </div>
      <div className="disclaimer">
        The quiz is not a medical assessment and your responses will not be shared with a medical provider. The Wellness uses your responses to personalize your experience and for other uses as described in our <a href="/privacy-policy" className="privacy-link">Privacy Policy</a>.
      </div>
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default TherapyPrimaryConcern;
