import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Therapy.css';
import logo from '../../../images/logo5.png';

const TherapyCTA = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleNext = () => {
    navigate('/enquiry');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
                         <img src={logo} alt="Logo" className="logo2" />
      <p className="fade-in-text">
        Based on your answers, we’ll pair you with a therapist who matches your needs. Book your first session today to take the next step.
      </p>
      <button className="button" onClick={handleNext}>
        Book Now
      </button>
    </div>
  );
};

export default TherapyCTA;
