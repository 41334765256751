import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { blogs } from './blogsData';
import './BlogDetail.css';

const BlogDetail = () => {
  const { id } = useParams();
  const blog = blogs.find((b) => b.id === id);

  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, [id]);

  if (!blog) {
    return <div className="blog-detail-container">Blog not found.</div>;
  }

  return (
    <div className="blog-detail-container">
      <h1 className="blog-detail-title">{blog.title}</h1>
      <p className="blog-detail-meta">
        {blog.date} | Written by {blog.author}
      </p>
      <div
        className="blog-detail-content"
        dangerouslySetInnerHTML={{ __html: blog.content }}
      />
    </div>
  );
};

export default BlogDetail;
