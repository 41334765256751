import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './GP.css';
import logo from '../../images/logo5.png';

const GPModeOfAppointment = () => {
  const navigate = useNavigate();
  const [preferredMode, setPreferredMode] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleContinue = () => {
    if (!preferredMode) {
      alert('Please select your preferred mode of appointment.');
      return;
    }
    navigate('/gp-cta');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
         <img src={logo} alt="Logo" className="logo2" />
      <h1>Would you prefer a telehealth consultation or an in-person visit?</h1>
      <div
        className={`option ${preferredMode === 'Telehealth' ? 'selected' : ''}`}
        onClick={() => setPreferredMode('Telehealth')}
      >
        Telehealth
      </div>
      <div
        className={`option ${preferredMode === 'In-person' ? 'selected' : ''}`}
        onClick={() => setPreferredMode('In-person')}
      >
        In-person
      </div>
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default GPModeOfAppointment;
