import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Weight.css';
import logo from '../../images/logo5.png';

const WeightLossIntroduction = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/weight-loss-health-metrics');
    }, 4000); // Auto-redirect after 5 seconds

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
      <img src={logo} alt="Logo" className="logo2" />
      <p className="fade-in-text">
        We’re here to personalise your weight loss journey. <br/><br/>
        Let’s collect a few details to craft a plan tailored to you.
      </p>
    </div>
  );
};

export default WeightLossIntroduction;
