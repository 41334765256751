// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-detail-container {
    font-family: 'Poppins', sans-serif;
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: transparent;
    border-radius: 10px;
    margin-top: 100px;
  }
  
  .blog-detail-title {
    font-size: 3rem;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .blog-detail-meta {
    font-size: 1.1rem;
    color: #777;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .blog-detail-content {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.8;
  }
  
  .blog-detail-content h2 {
    font-size: 1.7rem;
    color: #333;
    margin-top: 20px;
  }
  
  .blog-detail-content ul {
    padding-left: 20px;
    margin: 10px 0;
  }
  
  .blog-detail-content ul li {
    margin: 5px 0;
  }
  
  .blog-detail-content p {
    margin: 15px 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/BlogDetail.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".blog-detail-container {\n    font-family: 'Poppins', sans-serif;\n    max-width: 1200px;\n    margin: 40px auto;\n    padding: 20px;\n    background-color: transparent;\n    border-radius: 10px;\n    margin-top: 100px;\n  }\n  \n  .blog-detail-title {\n    font-size: 3rem;\n    color: #333;\n    margin-bottom: 10px;\n    text-align: center;\n  }\n  \n  .blog-detail-meta {\n    font-size: 1.1rem;\n    color: #777;\n    text-align: center;\n    margin-bottom: 30px;\n  }\n  \n  .blog-detail-content {\n    font-size: 1.2rem;\n    color: #555;\n    line-height: 1.8;\n  }\n  \n  .blog-detail-content h2 {\n    font-size: 1.7rem;\n    color: #333;\n    margin-top: 20px;\n  }\n  \n  .blog-detail-content ul {\n    padding-left: 20px;\n    margin: 10px 0;\n  }\n  \n  .blog-detail-content ul li {\n    margin: 5px 0;\n  }\n  \n  .blog-detail-content p {\n    margin: 15px 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
