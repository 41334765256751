import React, { useState, createContext } from 'react';
import { HashRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import HomePage from './pages/Homepage';
import EnquiryPage from './pages/EnquiryPage';
import Navbar from './components/NavBar';
import PRPLoading from './QUESTIONNAIRES/PRP/PRPLoading';
import PRPOptions from './QUESTIONNAIRES/PRP/PRPOptions';
import HairGoals from './QUESTIONNAIRES/PRP/PRPQuestions/HairGoals';
import HairChanges from './QUESTIONNAIRES/PRP/PRPQuestions/HairChanges';
import HairLossAmount from './QUESTIONNAIRES/PRP/PRPQuestions/HairLossAmount';
import HairLossTime from './QUESTIONNAIRES/PRP/PRPQuestions/HairLossTime';
import HairExpectations from './QUESTIONNAIRES/PRP/PRPQuestions/HairExpectations';
import HairType from './QUESTIONNAIRES/PRP/PRPQuestions/HairType';
import HairLength from './QUESTIONNAIRES/PRP/PRPQuestions/HairLength';
import './App.css';
import HairAnalysisResult from './QUESTIONNAIRES/PRP/PRPQuestions/HairAnalysisResults';
import TherapyPrimaryConcern from './QUESTIONNAIRES/THERAPY/Questions/TherapyPrimaryConcern';
import TherapyDurationSymptoms from './QUESTIONNAIRES/THERAPY/Questions/TherapyDurationSymptom';
import TherapyImpactDailyLife from './QUESTIONNAIRES/THERAPY/Questions/TherapyImpactDailyLife';
import TherapyPreviousTherapy from './QUESTIONNAIRES/THERAPY/Questions/TherapyPreviousTherapy';
import TherapyCTA from './QUESTIONNAIRES/THERAPY/Questions/TherapyCTA';
import TherapyIntroduction from './QUESTIONNAIRES/THERAPY/TherapyIntroduction';
import WeightLossIntroduction from './QUESTIONNAIRES/WEIGHT/WeightLossIntroduction';
import WeightLossHealthMetrics from './QUESTIONNAIRES/WEIGHT/WeightHealthMetrics';
import WeightLossGoals from './QUESTIONNAIRES/WEIGHT/WeightLossGoals';
import WeightLossDietaryHabits from './QUESTIONNAIRES/WEIGHT/WeightLossDietaryHabits';
import WeightLossExerciseRoutine from './QUESTIONNAIRES/WEIGHT/WeightLossExerciseRoutine';
import WeightLossMedicalHistory from './QUESTIONNAIRES/WEIGHT/WeightLossMedicalHistory';
import WeightLossCTA from './QUESTIONNAIRES/WEIGHT/WeightLossCTA';
import GPLifestyleFactors from './QUESTIONNAIRES/GP/GPLifestyleFactors';
import GPModeOfAppointment from './QUESTIONNAIRES/GP/GPModeOfAppointment';
import GPCTA from './QUESTIONNAIRES/GP/GPCTA';
import GPIntroduction from './QUESTIONNAIRES/GP/GPIntroduction';
import GPReasonAppointment from './QUESTIONNAIRES/GP/GPReasonAppointment';
import GPAcuteIllness from './QUESTIONNAIRES/GP/GPAcuteIllness';
import GPChronicCondition from './QUESTIONNAIRES/GP/GPChronicCondition';
import GPMedicalHistory from './QUESTIONNAIRES/GP/GPMedicalHistory';
import BloodTestsIntroduction from './QUESTIONNAIRES/BLOOD/BloodTestsIntroduction';
import BloodTestsReason from './QUESTIONNAIRES/BLOOD/BloodTestsReason';
import BloodTestsSymptoms from './QUESTIONNAIRES/BLOOD/BloodTestsSymptoms';
import BloodTestsMedicalHistory from './QUESTIONNAIRES/BLOOD/BloodTestsMedicalHistory';
import BloodTestsCTA from './QUESTIONNAIRES/BLOOD/BloodTestsCTA';
import ExosomesIntroduction from './QUESTIONNAIRES/EXOSOME/ExosomesIntroduction';
import ExosomesTreatmentGoals from './QUESTIONNAIRES/EXOSOME/ExosomesTreatmentGoals';
import ExosomesMedicalHistory from './QUESTIONNAIRES/EXOSOME/ExosomesMedicalHistory';
import ExosomesCTA from './QUESTIONNAIRES/EXOSOME/ExosomesCTA';
import JointPainLocation from './QUESTIONNAIRES/PRP/PRPQuestions/JointPainLocation';
import JointPainDiagnosis from './QUESTIONNAIRES/PRP/PRPQuestions/JointPainDiagnosis';
import SkinPreviousTreatments from './QUESTIONNAIRES/PRP/PRPQuestions/SkinPreviousTreatments';
import SkinRejuvenationPurpose from './QUESTIONNAIRES/PRP/PRPQuestions/SkinRejuvenationPurpose';
import BlogDetail from './pages/BlogDetail';
import BlogPage from './pages/BlogPage';
import WhatsAppButton from './components/WhatsAppButton';

export const TransitionContext = createContext();

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // If you no longer need triggers for transitions, you can remove this context or keep if used elsewhere
  const triggerTransition = (path) => {
    navigate(path);
  };

  return (
    <TransitionContext.Provider value={{ triggerTransition }}>
      <Navbar />
      <div className="homepage">
        <Routes location={location}>
          <Route path="/" element={<HomePage />} />
          <Route path="/blog" element={<BlogPage/>}/>
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/enquiry" element={<EnquiryPage />} />
          <Route path="/loading" element={<PRPLoading />} />
          <Route path="/prp-options" element={<PRPOptions />} />
          <Route path="/prp-hair-goals" element={<HairGoals />} />
          <Route path="/prp-hair-changes" element={<HairChanges />} />
          <Route path="/prp-hair-loss-amount" element={<HairLossAmount />} />
          <Route path="/prp-hair-loss-time" element={<HairLossTime />} />
          <Route path="/prp-hair-expectations" element={<HairExpectations />} />
          <Route path="/prp-hair-type" element={<HairType />} />
          <Route path="/prp-hair-length" element={<HairLength />} />
          <Route path="/hair-analysis-result" element={<HairAnalysisResult />} />
          <Route path="/therapy" element={<TherapyIntroduction />} />
          <Route path="/therapy-primary-concern" element={<TherapyPrimaryConcern />} />
          <Route path="/therapy-duration-symptoms" element={<TherapyDurationSymptoms />} />
          <Route path="/therapy-impact-daily-life" element={<TherapyImpactDailyLife />} />
          <Route path="/therapy-previous-therapy" element={<TherapyPreviousTherapy />} />
          <Route path="/therapy-cta" element={<TherapyCTA />} />
          <Route path="/weight-loss" element={<WeightLossIntroduction />} />
          <Route path="/weight-loss-health-metrics" element={<WeightLossHealthMetrics />} />
          <Route path="/weight-loss-goals" element={<WeightLossGoals />} />
          <Route path="/weight-loss-dietary-habits" element={<WeightLossDietaryHabits />} />
          <Route path="/weight-loss-exercise-routine" element={<WeightLossExerciseRoutine />} />
          <Route path="/weight-loss-medical-history" element={<WeightLossMedicalHistory />} />
          <Route path="/weight-loss-cta" element={<WeightLossCTA />} />
          <Route path="/private-gp" element={<GPIntroduction />} />
          <Route path="/gp-reason-appointment" element={<GPReasonAppointment />} />
          <Route path="/gp-acute-illness" element={<GPAcuteIllness />} />
          <Route path="/gp-chronic-condition" element={<GPChronicCondition />} />
          <Route path="/gp-medical-history" element={<GPMedicalHistory />} />
          <Route path="/gp-lifestyle-factors" element={<GPLifestyleFactors />} />
          <Route path="/gp-mode-appointment" element={<GPModeOfAppointment />} />
          <Route path="/gp-cta" element={<GPCTA />} />
          <Route path="/blood-tests" element={<BloodTestsIntroduction />} />
          <Route path="/blood-tests-reason" element={<BloodTestsReason />} />
          <Route path="/blood-tests-symptoms" element={<BloodTestsSymptoms />} />
          <Route path="/blood-tests-medical-history" element={<BloodTestsMedicalHistory />} />
          <Route path="/blood-tests-cta" element={<BloodTestsCTA />} />
          <Route path="/exosomes" element={<ExosomesIntroduction />} />
          <Route path="/exosomes-treatment-goals" element={<ExosomesTreatmentGoals />} />
          <Route path="/exosomes-medical-history" element={<ExosomesMedicalHistory />} />
          <Route path="/exosomes-cta" element={<ExosomesCTA />} />
          <Route path="/prp-joints" element={<JointPainLocation />} />
          <Route path="/joint-pain-diagnosis" element={<JointPainDiagnosis />} />
          <Route path="/skin-rejuvenation" element={<SkinRejuvenationPurpose />} />
          <Route path="/skin-previous-treatments" element={<SkinPreviousTreatments />} />
        </Routes>
      </div>
      <WhatsAppButton />
    </TransitionContext.Provider>
  );
};

export default App;
