import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Exosomes.css';
import logo from '../../images/logo5.png';

const ExosomesIntroduction = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/exosomes-treatment-goals');
    }, 5000); // Auto-redirect after 5 seconds

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
        <img src={logo} alt="Logo" className="logo2" />
      <p className="fade-in-text">
        Explore the regenerative power of exosome therapy. <br/>
        <br/>Let’s determine if it’s right for you.
      </p>
    </div>
  );
};

export default ExosomesIntroduction;
