import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Weight.css';
import logo from '../../images/logo5.png';

const WeightLossGoals = () => {
  const navigate = useNavigate();
  const [goal, setGoal] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleContinue = () => {
    if (!goal) {
      alert('Please provide your weight loss goal.');
      return;
    }
    navigate('/weight-loss-dietary-habits');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
        <img src={logo} alt="Logo" className="logo2" />
      <h1>What is your weight loss goal?</h1>
      <input
        type="text"
        placeholder="e.g., Lose 5kg, Reach 70kg"
        value={goal}
        onChange={(e) => setGoal(e.target.value)}
      />
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default WeightLossGoals;
