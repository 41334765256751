import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './GP.css';
import logo from '../../images/logo5.png';

const GPCTA = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleBookNow = () => {
    navigate('/enquiry');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
         <img src={logo} alt="Logo" className="logo2" />
      <p className="fade-in-text">
        Based on your responses, we recommend booking a consultation with our GP. <br/>
        <br/>
        Appointments are available same day. <br/>
      </p>
      <button className="button" onClick={handleBookNow}>
        Book Now
      </button>
    </div>
  );
};

export default GPCTA;
