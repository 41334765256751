import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './GP.css';
import logo from '../../images/logo5.png';

const GPIntroduction = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/gp-reason-appointment');
    }, 4000); // Auto-redirect after 5 seconds

    return () => clearTimeout(timer);
  }, [navigate]);


  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
         <img src={logo} alt="Logo" className="logo2" />
      <p className="fade-in-text">
        Welcome to The Wellness: London Clinic. <br/>
        <br/>
        Let’s gather some details to personalise your GP consultation. <br/>
        <br/>This will take 2 minutes or less.
      </p>
    </div>
  );
};

export default GPIntroduction;
