import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PRPQuestions.css';
import logo from '../../../images/logo5.png';

const SkinPreviousTreatments = () => {
  const navigate = useNavigate();
  const [hasPreviousTreatments, setHasPreviousTreatments] = useState(null);
  const [treatmentDetails, setTreatmentDetails] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleContinue = () => {
    if (hasPreviousTreatments === null) {
      alert('Please indicate if you have tried other skin treatments before.');
      return;
    }
    if (hasPreviousTreatments === 'Yes' && !treatmentDetails) {
      alert('Please provide details about your previous skin treatments.');
      return;
    }
    navigate('/enquiry');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
      <img src={logo} alt="Logo" className="logo2" />
      <h1>Have you tried other skin treatments before (e.g., fillers, microneedling)?</h1>
      <div
        className={`option ${hasPreviousTreatments === 'Yes' ? 'selected' : ''}`}
        onClick={() => setHasPreviousTreatments('Yes')}
      >
        Yes
      </div>
      <div
        className={`option ${hasPreviousTreatments === 'No' ? 'selected' : ''}`}
        onClick={() => setHasPreviousTreatments('No')}
      >
        No
      </div>
      {hasPreviousTreatments === 'Yes' && (
        <textarea
          placeholder="Please describe your previous treatments"
          value={treatmentDetails}
          onChange={(e) => setTreatmentDetails(e.target.value)}
        />
      )}
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default SkinPreviousTreatments;
