import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Weight.css';
import logo from '../../images/logo5.png';

const WeightLossHealthMetrics = () => {
  const navigate = useNavigate();
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [bmi, setBmi] = useState(null);
  const [classification, setClassification] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const calculateBMI = () => {
    if (weight && height) {
      const calculatedBMI = (weight / ((height / 100) ** 2)).toFixed(1);
      setBmi(calculatedBMI);
      if (calculatedBMI < 18.5) setClassification('Underweight');
      else if (calculatedBMI < 25) setClassification('Normal');
      else if (calculatedBMI < 30) setClassification('Overweight');
      else setClassification('Obese');
    }
  };

  const handleContinue = () => {
    if (!weight || !height) {
      alert('Please provide both weight and height.');
      return;
    }
    navigate('/weight-loss-goals');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
        <img src={logo} alt="Logo" className="logo2" />
      <h1>What is your current weight (kg) and height (cm)?</h1>
      <input
        type="number"
        placeholder="Weight (kg)"
        value={weight}
        onChange={(e) => setWeight(e.target.value)}
      />
      <input
        type="number"
        placeholder="Height (cm)"
        value={height}
        onChange={(e) => setHeight(e.target.value)}
      />
      <button className="button" onClick={calculateBMI}>
        Calculate BMI
      </button>
      {bmi && (
        <p>
          Your BMI is <strong>{bmi}</strong> ({classification}).
        </p>
      )}
            <div className="disclaimer">
        The quiz is not a medical assessment and your responses will not be shared with a medical provider. The Wellness uses your responses to personalize your experience and for other uses as described in our <a href="/privacy-policy" className="privacy-link">Privacy Policy</a>.
      </div>
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default WeightLossHealthMetrics;
