import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PRPQuestions.css';
import logo from '../../../images/logo5.png';

const JointPainLocation = () => {
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [otherLocation, setOtherLocation] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.includes(option)
        ? prevOptions.filter((opt) => opt !== option) // Remove if already selected
        : [...prevOptions, option] // Add if not selected
    );
    if (option === 'Other') {
      setOtherLocation(''); // Clear other input if "Other" is toggled
    }
  };

  const handleContinue = () => {
    if (selectedOptions.length === 0) {
      alert('Please select at least one joint location.');
      return;
    }
    if (selectedOptions.includes('Other') && !otherLocation) {
      alert('Please specify the other joint location.');
      return;
    }
    navigate('/joint-pain-diagnosis');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
           <img src={logo} alt="Logo" className="logo2" />
      <h1>Which joint(s) are you experiencing pain in?</h1>
      {['Knee', 'Shoulder', 'Elbow', 'Hip', 'Other'].map((joint) => (
        <div
          key={joint}
          className={`option ${selectedOptions.includes(joint) ? 'selected' : ''}`}
          onClick={() => handleOptionClick(joint)}
        >
          {joint}
        </div>
      ))}
      {selectedOptions.includes('Other') && (
        <textarea
          placeholder="Please specify other joint location"
          value={otherLocation}
          onChange={(e) => setOtherLocation(e.target.value)}
        />
      )}
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default JointPainLocation;
