import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Weight.css';
import logo from '../../images/logo5.png';

const WeightLossMedicalHistory = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption((prevOptions) =>
      prevOptions.includes(option)
        ? prevOptions.filter((opt) => opt !== option)
        : [...prevOptions, option]
    );
  };

  const handleContinue = () => {
    navigate('/weight-loss-cta');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
        <img src={logo} alt="Logo" className="logo2" />
      <h1>Do you have a history of any of the following conditions?</h1>
      {['Diabetes', 'Thyroid disorders', 'Heart conditions', 'None of the above'].map((condition) => (
        <div
          key={condition}
          className={`option ${selectedOption.includes(condition) ? 'selected' : ''}`}
          onClick={() => handleOptionClick(condition)}
        >
          {condition}
        </div>
      ))}
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default WeightLossMedicalHistory;
