// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: white;
}

/* Hero Section */
.hero {
  /* Remove min-height so it doesn't center vertically and shift around */
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  background: white;
  color: black;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  /* Add a top padding to ensure the section starts just below the navbar */
  padding-top: 120px;
  padding-bottom: 60px;
}

/* You can adjust padding-top here to control how close it sits under the navbar */

/* Media Queries */
@media (max-width: 1000px) {
  .hero {
    padding-top: 100px;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-description {
    font-size: 1.2rem;
  }
}

@media (max-width: 750px) {
  .hero-title {
    font-size: 2rem;
  }
  
  .hero-description {
    font-size: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/HomePage.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,SAAS;EACT,UAAU;EACV,uBAAuB;AACzB;;AAEA,iBAAiB;AACjB;EACE,uEAAuE;EACvE,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,iBAAiB;EACjB,YAAY;;EAEZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;;EAElB,yEAAyE;EACzE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA,kFAAkF;;AAElF,kBAAkB;AAClB;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":["body {\n  font-family: 'Poppins', sans-serif;\n  margin: 0;\n  padding: 0;\n  background-color: white;\n}\n\n/* Hero Section */\n.hero {\n  /* Remove min-height so it doesn't center vertically and shift around */\n  width: 100%;\n  max-width: 1400px;\n  margin: 0 auto;\n  background: white;\n  color: black;\n  \n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  \n  /* Add a top padding to ensure the section starts just below the navbar */\n  padding-top: 120px;\n  padding-bottom: 60px;\n}\n\n/* You can adjust padding-top here to control how close it sits under the navbar */\n\n/* Media Queries */\n@media (max-width: 1000px) {\n  .hero {\n    padding-top: 100px;\n  }\n\n  .hero-title {\n    font-size: 2.5rem;\n  }\n\n  .hero-description {\n    font-size: 1.2rem;\n  }\n}\n\n@media (max-width: 750px) {\n  .hero-title {\n    font-size: 2rem;\n  }\n  \n  .hero-description {\n    font-size: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
