import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation
import './Navbar.css';
import logo from '../images/logo5.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate function
  const location = useLocation(); // Get the current location

  // List of routes where the navbar should be hidden
  const hiddenRoutes = [
    '/loading',
    '/prp-options',
    '/prp-hair-goals',
    '/prp-hair-changes',
    '/prp-hair-loss-amount',
    '/prp-hair-loss-time',
    '/prp-hair-expectations',
    '/prp-hair-type',
    '/prp-hair-length',
    '/hair-analysis-result',
    '/therapy',
    '/therapy-primary-concern',
    '/therapy-duration-symptoms',
    '/therapy-impact-daily-life',
    '/therapy-previous-therapy',
    '/therapy-cta',
    '/weight-loss',
    '/weight-loss-health-metrics',
    '/weight-loss-goals',
    '/weight-loss-dietary-habits',
    '/weight-loss-exercise-routine',
    '/weight-loss-medical-history',
    '/weight-loss-cta',
    '/private-gp',
    '/gp-reason-appointment',
    '/gp-acute-illness',
    '/gp-chronic-condition',
    '/gp-medical-history',
    '/gp-lifestyle-factors',
    '/gp-mode-appointment',
    '/gp-cta',
    '/blood-tests',
    '/blood-tests-reason',
    '/blood-tests-symptoms',
    '/blood-tests-medical-history',
    '/blood-tests-cta',
    '/exosomes',
    '/exosomes-treatment-goals',
    '/exosomes-medical-history',
    '/exosomes-cta',
    '/prp-joints',
    '/joint-pain-diagnosis',
    '/skin-rejuvenation',
    '/skin-previous-treatments',
  ];

  // Check if the current route is in the hiddenRoutes list
  const isNavbarHidden = hiddenRoutes.includes(location.pathname);

  // Disable body scroll when the menu is open
  React.useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  const handleBookNowClick = () => {
    setIsMenuOpen(false); 
    navigate('/enquiry'); // Navigate to the enquiry page
  };

  const handleBlogClick = () => {
    setIsMenuOpen(false); 
    navigate('/blog'); // Navigate to the blog page
  };

  const handleServicesClick = () => {
    setIsMenuOpen(false); 
    navigate('/blog'); // Navigate to services page (currently set to blog)
  };

  const handleHomeClick = () => {
    setIsMenuOpen(false); 
    navigate('/'); // Navigate to the home page
  };

  // Toggle the mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // If the navbar should be hidden on the current route, return null
  if (isNavbarHidden) return null;

  return (
    <div className="bar">
      <div className="navbar">
        <div className="logo" onClick={handleHomeClick}>
          <img
            src={logo}
            alt="Moccet Logo"
            className="logo-image"
          />
        </div>

        {/* Hamburger / Close Icon for Mobile */}
        <div className="mobile-menu-icon" onClick={toggleMenu}>
          {isMenuOpen ? (
            <span className="close-icon">&times;</span>
          ) : (
            <span className="hamburger-icon">&#9776;</span>
          )}
        </div>

        <div className={`links-container ${isMenuOpen ? 'open' : ''}`}>
          <div className={`links ${isMenuOpen ? 'mobile-dropdown' : ''}`}>
            <p className="nav-link" onClick={handleBlogClick}>Blog</p>
            <p className="nav-link" onClick={handleServicesClick}>Services</p>
            <p className="nav-link" onClick={() => setIsMenuOpen(false)}>About</p>
          </div>
          <p
            className="send-nuqoot-btn nav-link1"
            onClick={handleBookNowClick}
          >
            Book Now
          </p>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
