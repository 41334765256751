import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BlogPage.css';
import PRPImage from '../images/PRPImage.png';
import Logo from '../images/logo4.png';
import Exosome from '../images/exosome.png';
import Wellness from '../images/wellness2.png';
import NAD from '../images/NAD.png';
import Prp2 from '../images/PRP2.png';
import Glp from '../images/GLP-1.png';
import Energy from '../images/energy.png';
import Old from '../images/old.png';
import Consult from '../images/consult.png';
import Nutrition from '../images/nutrition.png';
import Footer from '../components/Footer';
import Magnesium from '../images/magnesium.png';
import Skincare from '../images/skincare.png';
import Fatigue from '../images/fatigue.png';
import Hormone from '../images/hormone.png';

const blogs = [
    {
        id: 'Hormone-Check-Signs',
        title: '5 Signs You Need a Hormone Check',
        author: 'The Wellness London',
        date: 'December 14, 2024',
        category: 'Health & Wellness',
        imageUrl: Hormone,
        snippet: 'Your hormones are the body’s master regulators, influencing everything from energy levels and mood to weight and skin health...',
    },
    {
        id: 'Magnesium-Guide',
        title: 'How Much Magnesium Should You Be Taking? A Guide from The Wellness London',
        snippet: 'Magnesium is often called the "relaxation mineral" because of its vital role in over 300 enzymatic reactions in the body...',
        date: 'December 12, 2024',
        category: 'Nutrition & Wellness',
        imageUrl: Magnesium,
    },
    {
        id: 'Late-Night-Snacking',
        title: 'Late-Night Snacking: How It Affects Your Weight—and What to Do About It',
        snippet: 'Is your late-night snacking habit sabotaging your health goals? Let’s unpack the science.',
        date: 'December 10, 2024',
        category: 'Nutrition & Wellness',
        imageUrl: Nutrition
      },  
      {
        id: 'Beating-Winter-Fatigue',
        title: 'Beating Winter Fatigue: Expert-Backed Ways to Restore Energy',
        snippet: 'Do you feel more tired than usual during winter? You’re not alone. Reduced sunlight, colder weather, and lifestyle changes can leave you feeling drained...',
        date: 'December 9, 2024',
        category: 'Health & Wellness',
        imageUrl: Fatigue,
      },
      {
        id: 'Winter-Skincare-Tips',
        title: 'Winter Skincare Made Simple: Expert Tips for Radiant Skin All Season',
        snippet: 'As the temperatures drop and the heating cranks up, your skin might feel tight, flaky, and irritated. Winter is notorious for wreaking havoc on your skin’s hydration...',
        date: 'December 7, 2024',
        category: 'Skincare & Beauty',
        imageUrl: Skincare,
          },    
  {
    id: 'PRP-Hair-Regrowth',
    title: 'How Many PRP Sessions Are Needed for Hair Growth, and Why?',
    snippet: 'Hair loss can be a deeply personal journey, and finding effective, non-surgical solutions is a priority for many...',
    date: 'November 2, 2024',
    category: 'Regenerative Medicine',
    imageUrl: PRPImage
  },
  {
    id: 'Personalised-Healthcare-Redefined',
    title: 'Personalised Healthcare Redefined: The Wellness Approach to Proactive Health',
    snippet: 'In today’s fast-paced world, taking care of your health isn’t just about treating illness; it’s about preventing it altogether...',
    date: 'October 15, 2024',
    category: 'Preventative Care',
    imageUrl: Logo
  },
  {
    id: 'Hair-Restoration-Made-Simple',
    title: 'Hair Restoration Made Simple: PRP, Exosome Therapy, and Beyond',
    snippet: 'Hair loss is more common than you think, affecting 40% of men by age 35 and 50% of women by age 50...',
    date: 'October 20, 2024',
    category: 'Hair Restoration',
    imageUrl: Exosome
  },
  {
    id: 'Future-of-Regenerative-Medicine',
    title: 'The Future of Regenerative Medicine: How Exosome Therapy Is Transforming Health and Beauty',
    snippet: 'Regenerative medicine is one of the most exciting advancements in healthcare, offering solutions that go beyond symptom management...',
    date: 'October 25, 2024',
    category: 'Regenerative Medicine',
    imageUrl: NAD
  },
  {
    id: 'Full-Biomarker-Testing',
    title: 'Unlocking Peak Health: The Power of Full Biomarker Testing and Personalised Care',
    snippet: 'When was the last time you had a full health check-up? In today’s fast-paced world, many of us ignore subtle warning signs...',
    date: 'October 30, 2024',
    category: 'Preventative Care',
    imageUrl: Old
  },
  {
    id: 'Achieving-Sustainable-Weight-Loss',
    title: 'Achieving Sustainable Weight Loss: How Personalised Plans and Medical Expertise Make the Difference',
    snippet: 'Losing weight is one of the most common health goals, but it’s also one of the most challenging to achieve—and maintain...',
    date: 'November 5, 2024',
    category: 'Weight Management',
    imageUrl: Wellness
  },
  {
    id: 'Regular-Blood-Tests-Add-Years',
    title: 'How Regular Blood Tests Can Add Years to Your Life',
    snippet: 'How often do you really think about what’s happening inside your body? While you might feel fine, underlying health issues...',
    date: 'November 10, 2024',
    category: 'Preventative Care',
    imageUrl: Energy
  },
  {
    id: 'PRP-Therapy-Uncovered',
    title: 'PRP Therapy Uncovered: The Science Behind Hair Restoration, Joint Healing, and Skin Rejuvenation',
    snippet: 'Regenerative medicine is no longer a futuristic concept—it’s here, and it’s transforming how we treat hair loss, joint pain, and skin aging...',
    date: 'November 15, 2024',
    category: 'Regenerative Medicine',
    imageUrl: PRPImage
  },
  {
    id: 'Same-Day-Private-GP',
    title: 'The Ultimate Guide to Same-Day Private GP Appointments',
    snippet: 'Life doesn’t wait, and neither should you when it comes to your health. Whether you’re feeling unwell or need urgent advice...',
    date: 'November 18, 2024',
    category: 'Primary Care',
    imageUrl: Consult
  },
  {
    id: 'Full-Biomarker-Longevity',
    title: 'Revolutionising Health with Full Biomarker Testing: Why It’s the Secret to Longevity',
    snippet: 'In an era where chronic illnesses account for over 70% of global deaths, preventative care is essential...',
    date: 'November 22, 2024',
    category: 'Longevity',
    imageUrl: Prp2
  },
  {
    id: 'Weight-Loss-Revolution',
    title: 'The Weight Loss Revolution: Why Personalised Plans Are the Key to Long-Term Success',
    snippet: 'Losing weight is more than a numbers game—it’s about understanding your body and addressing underlying health factors...',
    date: 'November 25, 2024',
    category: 'Weight Management',
    imageUrl: Glp
  },
];

const BlogPage = () => {
  const navigate = useNavigate();

  const handleReadMore = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <div className="blog-page-wrapper">
      <div className="blog-hero-section">
        <h1 className="hero-title">Insights & Ideas</h1>
        <p className="hero-subtitle">Stay updated with the latest insights and trends</p>
      </div>

      <div className="blog-container">
        {blogs.map((blog) => (
          <div key={blog.id} className="blog-card" onClick={() => handleReadMore(blog.id)}>
            <div className="blog-image-wrapper">
              <img src={blog.imageUrl} alt={blog.title} className="blog-image" />
            </div>
            <div className="blog-meta">
              <span className="blog-category">{blog.category}</span>
              <span className="blog-date">{blog.date}</span>
            </div>
            <h2 className="blog-title">{blog.title}</h2>
            <p className="blog-snippet">{blog.snippet}</p>
            <div className="read-more-link">
              Read Article →
            </div>
          </div>
        ))}
      </div>
      <Footer/>
    </div>
  );
};

export default BlogPage;
