import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PRPQuestions.css';
import logo from '../../../images/logo5.png';

const JointPainDiagnosis = () => {
  const navigate = useNavigate();
  const [diagnosedCondition, setDiagnosedCondition] = useState(null);
  const [diagnosisDetails, setDiagnosisDetails] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleContinue = () => {
    if (diagnosedCondition === null) {
      alert('Please indicate if you have been diagnosed with arthritis or a joint condition.');
      return;
    }
    if (diagnosedCondition === 'Yes' && !diagnosisDetails) {
      alert('Please provide details about your diagnosis.');
      return;
    }
    navigate('/enquiry');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
         <img src={logo} alt="Logo" className="logo2" />
      <h1>Have you been diagnosed with arthritis or a joint condition?</h1>
      <div
        className={`option ${diagnosedCondition === 'Yes' ? 'selected' : ''}`}
        onClick={() => setDiagnosedCondition('Yes')}
      >
        Yes
      </div>
      <div
        className={`option ${diagnosedCondition === 'No' ? 'selected' : ''}`}
        onClick={() => setDiagnosedCondition('No')}
      >
        No
      </div>
      {diagnosedCondition === 'Yes' && (
        <textarea
          placeholder="Please describe your diagnosis"
          value={diagnosisDetails}
          onChange={(e) => setDiagnosisDetails(e.target.value)}
        />
      )}
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default JointPainDiagnosis;
