import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import './Services.css';

import gpAppointmentImage from '../images/steth.png';
import blood from '../images/blood.png';
import weightLossImage from '../images/ozempic.png';
import therapyImage from '../images/therapy.png';
import bloodTestsImage from '../images/blood2.png';
import exosomesImage from '../images/exosome1.png';

const Services = () => {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  
  const placeholders = [
    { text: 'PRP', color: '#0048a6' },
    { text: 'Private GP Appointment', color: '#946b87' },
    { text: 'Exosomes', color: '#af921a' },
    { text: 'Weight Loss', color: '#6eb4cf' },
    { text: 'Therapy', color: '#9d1918' },
    { text: 'Blood Tests', color: '#5b8275' },
  ];

  const features = [
    {
      title: 'Private GP',
      image: gpAppointmentImage,
      link: '/private-gp',
    },
    {
      title: 'Platelet-rich plasma',
      image: blood,
      link: '/loading',
    },
    {
      title: 'Weight Loss',
      image: weightLossImage,
      link: '/weight-loss',
    },
    {
      title: 'Therapy',
      image: therapyImage,
      link: 'therapy',
    },
    {
      title: 'Blood Tests',
      image: bloodTestsImage,
      link: '/blood-tests',
    },
    {
      title: 'Exosomes',
      image: exosomesImage,
      link: '/exosomes',
    },
  ];

  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  // Placeholder text animation
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
      }, 400);
      setTimeout(() => {
        setIsAnimating(false);
      }, 800);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  // Intersection Observer for animations
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          entries[0].target.classList.add('in-view');
          observer.unobserve(entries[0].target);
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleFeatureClick = (link) => {
    navigate(link);
  };

  return (
    <div className="how-it-works-container" ref={containerRef}>
      <div className="text-container">
        <h1
          className={`placeholder-text ${isAnimating ? 'animate' : ''}`}
          style={{ color: placeholders[currentPlaceholderIndex].color }}
        >
          {placeholders[currentPlaceholderIndex].text}
        </h1>
        <h1 className="personalised-text">personalised to you</h1>
      </div>
      <div className="services-scroll-container">
        <div className="features-grid">
          {features.map((feature, index) => (
            <div className="feature-card" key={index} onClick={() => handleFeatureClick(feature.link)}>
              <div className="feature-content">
                <div className="feature-text">
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                </div>
                <div className="feature-image-container">
  <img
    src={feature.image}
    alt={feature.title}
    className="feature-image"
  />
  <span className="arrow-icon">&gt;</span>
</div>

              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
