// HomePage.js

import React from 'react';
import './HomePage.css'; 
import Services from '../components/Services';
import Footer from '../components/Footer';
import FAQ from '../components/FAQ';
import PopularTreatments from '../components/PopularTreatments';

const HomePage = () => {
  return (
    <div>
      {/* First Hero Section */}
      <section className="hero">
      <Services />
      </section>
      <section className="hero">
      <PopularTreatments />
      </section>
      <section className="hero">
      <FAQ />
      </section>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default HomePage;
