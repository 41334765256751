import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './GP.css';
import logo from '../../images/logo5.png';

const GPLifestyleFactors = () => {
  const navigate = useNavigate();
  const [smokesOrDrinks, setSmokesOrDrinks] = useState(null);
  const [frequency, setFrequency] = useState('');
  const [exercisesRegularly, setExercisesRegularly] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleContinue = () => {
    if (smokesOrDrinks === null || exercisesRegularly === null) {
      alert('Please answer all questions before continuing.');
      return;
    }
    navigate('/gp-mode-appointment');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
         <img src={logo} alt="Logo" className="logo2" />
      <h1>Do you smoke or drink alcohol?</h1>
      <div
        className={`option ${smokesOrDrinks === 'Yes' ? 'selected' : ''}`}
        onClick={() => setSmokesOrDrinks('Yes')}
      >
        Yes
      </div>
      <div
        className={`option ${smokesOrDrinks === 'No' ? 'selected' : ''}`}
        onClick={() => setSmokesOrDrinks('No')}
      >
        No
      </div>
      {smokesOrDrinks === 'Yes' && (
        <textarea
          placeholder="How often?"
          value={frequency}
          onChange={(e) => setFrequency(e.target.value)}
        />
      )}
      <h1>Do you exercise regularly?</h1>
      <div
        className={`option ${exercisesRegularly === 'Yes' ? 'selected' : ''}`}
        onClick={() => setExercisesRegularly('Yes')}
      >
        Yes
      </div>
      <div
        className={`option ${exercisesRegularly === 'No' ? 'selected' : ''}`}
        onClick={() => setExercisesRegularly('No')}
      >
        No
      </div>
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default GPLifestyleFactors;
