import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Exosomes.css';
import logo from '../../images/logo5.png';

const ExosomesMedicalHistory = () => {
  const navigate = useNavigate();
  const [hasConditions, setHasConditions] = useState(null);
  const [conditionsDescription, setConditionsDescription] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleContinue = () => {
    if (hasConditions === null) {
      alert('Please indicate if you have a medical history.');
      return;
    }
    if (hasConditions === 'Yes' && !conditionsDescription) {
      alert('Please describe your medical history.');
      return;
    }
    navigate('/exosomes-cta');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
        <img src={logo} alt="Logo" className="logo2" />
      <h1>Do you have any conditions that may affect your suitability for treatment?</h1>
      <p>(e.g., autoimmune conditions, cancer)</p>
      <div
        className={`option ${hasConditions === 'Yes' ? 'selected' : ''}`}
        onClick={() => setHasConditions('Yes')}
      >
        Yes
      </div>
      <div
        className={`option ${hasConditions === 'No' ? 'selected' : ''}`}
        onClick={() => setHasConditions('No')}
      >
        No
      </div>
      {hasConditions === 'Yes' && (
        <textarea
          placeholder="Please describe your medical history"
          value={conditionsDescription}
          onChange={(e) => setConditionsDescription(e.target.value)}
        />
      )}
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default ExosomesMedicalHistory;
