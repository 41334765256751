import React from 'react';
import './PopularTreatments.css';
import ozempic from '../images/ozempic.png';
import therapyImage from '../images/therapy.png';
import bloodTestsImage from '../images/blood2.png';
import exosomesImage from '../images/exosome1.png';

const treatments = [
  {
    category: "Weight",
    title: "GLP-1 Injections",
    bgColor: "#D2A46F",
    image: ozempic
  },
  {
    category: "Energy",
    title: "Biomarker Blood Screen",
    bgColor: "#5A8CAC",
    image: bloodTestsImage
  },
  {
    category: "Anxiety",
    title: "Personalised Therapy",
    bgColor: "#2B9046",
    image: therapyImage
  },
  {
    category: "Skin",
    title: "Exosome Treatment",
    bgColor: "#9E7C59",
    image: exosomesImage
  }
];

const PopularTreatments = () => {
  return (
<div className="popular-treatments-wrapper">
  <div className="title-container">
    <h1 className="main-title">
      Popular <span className="highlight">treatments</span>
    </h1>
  </div>

  <div className="popular-treatments-container">
    <div className="cards-scroll-container">
      {treatments.map((treatment, index) => (
        <div
          key={index}
          className="treatment-card"
          style={{ backgroundColor: treatment.bgColor }}
        >
          <div className="card-content">
            {/* Top section: category, title, image */}
            <div className="top-section">
              <p className="category">{treatment.category}</p>
              <h2 className="treatment-title">{treatment.title}</h2>
              <img src={treatment.image} alt={treatment.title} className="treatment-image" />
            </div>
            
            {/* Bottom section: safety info and buttons */}
            <div className="bottom-section">
              <a href="#safety" className="safety-info">Important safety info</a>
              <div className="buttons-container">
                <button className="get-started">Get started</button>
                <button className="learn-more">Learn more</button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
    <p className="footer-note"></p>
  </div>
</div>

  );
};

export default PopularTreatments;
