// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page{
background-color: white;
height: 100%;
width: 100%;
margin: 0 auto;
opacity: 0; /* Start invisible */
transform: translateY(20px); /* Start slightly below */
transition: opacity 0.8s ease-out, transform 0.8s ease-out; /* Smooth fade-in and slide-up */
}

.page.fade-in {
opacity: 1; /* Fully visible */
transform: translateY(0); /* Original position */
}

.enquiry-container {
    max-width: 600px;
    margin: 50px auto;
    background-color: white;
    margin-top: 200px;
    padding: 20px;
  }
  
  .enquiry-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 15px;
    font-weight: 500;
    width: 100%;
  }
  
  input,
  select,
  button {
    padding: 10px;
    margin-top: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: transparent;
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box; /* Ensures consistent width */
  }
  
  button {
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #686868;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/EnquiryPage.css"],"names":[],"mappings":"AAAA;AACA,uBAAuB;AACvB,YAAY;AACZ,WAAW;AACX,cAAc;AACd,UAAU,EAAE,oBAAoB;AAChC,2BAA2B,EAAE,yBAAyB;AACtD,0DAA0D,EAAE,gCAAgC;AAC5F;;AAEA;AACA,UAAU,EAAE,kBAAkB;AAC9B,wBAAwB,EAAE,sBAAsB;AAChD;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,iBAAiB;IACjB,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;EACb;;EAEA;;;IAGE,aAAa;IACb,eAAe;IACf,eAAe;IACf,sBAAsB;IACtB,6BAA6B;IAC7B,WAAW;IACX,mBAAmB;IACnB,sBAAsB,EAAE,6BAA6B;EACvD;;EAEA;IACE,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".page{\nbackground-color: white;\nheight: 100%;\nwidth: 100%;\nmargin: 0 auto;\nopacity: 0; /* Start invisible */\ntransform: translateY(20px); /* Start slightly below */\ntransition: opacity 0.8s ease-out, transform 0.8s ease-out; /* Smooth fade-in and slide-up */\n}\n\n.page.fade-in {\nopacity: 1; /* Fully visible */\ntransform: translateY(0); /* Original position */\n}\n\n.enquiry-container {\n    max-width: 600px;\n    margin: 50px auto;\n    background-color: white;\n    margin-top: 200px;\n    padding: 20px;\n  }\n  \n  .enquiry-container h1 {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  label {\n    margin-bottom: 15px;\n    font-weight: 500;\n    width: 100%;\n  }\n  \n  input,\n  select,\n  button {\n    padding: 10px;\n    margin-top: 5px;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    background-color: transparent;\n    width: 100%;\n    border-radius: 10px;\n    box-sizing: border-box; /* Ensures consistent width */\n  }\n  \n  button {\n    background-color: black;\n    color: white;\n    border: none;\n    cursor: pointer;\n    margin-top: 20px;\n    font-size: 16px;\n  }\n  \n  button:hover {\n    background-color: #686868;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
