import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PRPQuestions.css';
import logo from '../../../images/logo5.png';

const HairExpectations = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // State to handle animation visibility

  useEffect(() => {
    setIsVisible(true); // Trigger the fade-in animation on mount
  }, []);

  
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleContinue = () => {
    if (!selectedOption) {
      alert('Please select an option before continuing.');
      return;
    }
    navigate('/prp-hair-type');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
              <img src={logo} alt="Logo" className="logo2" />
      <h1>With treatment, what results are you hoping for?</h1>
      <div
        className={`option ${selectedOption === 'Stronger hairline' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Stronger hairline')}
      >
        A stronger, defined hairline
      </div>
      <div
        className={`option ${selectedOption === 'Thicker hair' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Thicker hair')}
      >
        Visibly thicker, fuller hair
      </div>
      <div
        className={`option ${selectedOption === 'More coverage' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('More coverage')}
      >
        More scalp coverage
      </div>
      <div
        className={`option ${selectedOption === 'Keep hair' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Keep hair')}
      >
        Keep the hair I have
      </div>
      <div
        className={`option ${selectedOption === 'All of the above' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('All of the above')}
      >
        All of the above
      </div>
      <button className="button" onClick={handleContinue}>Continue</button>
    </div>
  );
};

export default HairExpectations;
