import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PRPQuestions.css';

const HairAnalysisResult = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false); // Track fade-in visibility

  useEffect(() => {
    setIsVisible(true); // Trigger fade-in effect when the component mounts
  }, []);

  const handleNext = () => {
    navigate('/enquiry'); // Navigate to the enquiry page
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
      <p className="fade-in-text">
        The Wellness Intelligence has now completed its analysis of your hair loss. <br/><br/>You are a candidate for combination therapy including PRP treatment, vitamins, and hair oils.
      </p>
      <button className="button" onClick={handleNext}>
        Next
      </button>
    </div>
  );
};

export default HairAnalysisResult;
