import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PRPQuestions.css';
import logo from '../../../images/logo5.png';

const HairLength = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // State to handle animation visibility

  useEffect(() => {
    setIsVisible(true); // Trigger the fade-in animation on mount
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleContinue = () => {
    if (!selectedOption) {
      alert('Please select an option before continuing.');
      return;
    }
    navigate('/hair-analysis-result'); // Navigate to the new result page
  };
  
  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
              <img src={logo} alt="Logo" className="logo2" />
      <h1>How long is your hair?</h1>
      <div
        className={`option ${selectedOption === 'Buzzed or bald' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Buzzed or bald')}
      >
        Buzzed, shaved, or bald
      </div>
      <div
        className={`option ${selectedOption === 'Short' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Short')}
      >
        Short
      </div>
      <div
        className={`option ${selectedOption === 'Medium' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Medium')}
      >
        Medium
      </div>
      <div
        className={`option ${selectedOption === 'Long' ? 'selected' : ''}`}
        onClick={() => handleOptionClick('Long')}
      >
        Long
      </div>
      <button className="button" onClick={handleContinue}>Finish</button>
    </div>
  );
};

export default HairLength;
