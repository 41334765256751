import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PRPQuestions.css';
import logo from '../../../images/logo5.png';

const SkinRejuvenationPurpose = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleContinue = () => {
    if (!selectedOption) {
      alert('Please select the purpose of your PRP treatment.');
      return;
    }
    navigate('/skin-previous-treatments');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
        <img src={logo} alt="Logo" className="logo2" />
      <h1>
        Are you seeking PRP for anti-ageing, scar reduction, or general rejuvenation?
      </h1>
      {['Anti-ageing', 'Scar reduction', 'General rejuvenation'].map((purpose) => (
        <div
          key={purpose}
          className={`option ${selectedOption === purpose ? 'selected' : ''}`}
          onClick={() => handleOptionClick(purpose)}
        >
          {purpose}
        </div>
      ))}
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default SkinRejuvenationPurpose;
