import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './BloodTests.css';
import logo from '../../images/logo5.png';

const BloodTestsIntroduction = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/blood-tests-reason');
    }, 4000); // Auto-redirect after 5 seconds

    return () => clearTimeout(timer);
  }, [navigate]);


  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
      <img src={logo} alt="Logo" className="logo2" />
      <p className="fade-in-text">
        We offer comprehensive blood tests tailored to your needs. <br/>
        <br/> Let’s find the right panel for you.
      </p>
    </div>
  );
};

export default BloodTestsIntroduction;
