import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PRPQuestions/PRPQuestions.css'; // Import the shared CSS
import logo from '../../images/logo5.png';

const PRPOptions = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false); // State to handle animation visibility

  useEffect(() => {
    setIsVisible(true); // Trigger the fade-in animation on mount
  }, []);

  const handleOptionClick = (option) => {
    if (option === 'Hair') navigate('/prp-hair-goals');
    else if (option === 'Joints') navigate('/prp-joints'); // Example navigation for Joints
    else if (option === 'Face') navigate('/skin-rejuvenation'); // Example navigation for Face
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
                      <img src={logo} alt="Logo" className="logo2" />
      <h1>Which area are you seeking PRP treatment for?</h1>
      <div className="option" onClick={() => handleOptionClick('Hair')}>Hair Loss</div>
      <div className="option" onClick={() => handleOptionClick('Joints')}>Joint Pain</div>
      <div className="option" onClick={() => handleOptionClick('Face')}>Skin Rejuvenation (face)</div>
      <div className="disclaimer">
        The quiz is not a medical assessment and your responses will not be shared with a medical provider. The Wellness uses your responses to personalize your experience and for other uses as described in our <a href="/privacy-policy" className="privacy-link">Privacy Policy</a>.
      </div>
    </div>
  );
};

export default PRPOptions;
