import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './GP.css';
import logo from '../../images/logo5.png';

const GPAcuteIllness = () => {
  const navigate = useNavigate();
  const [symptoms, setSymptoms] = useState('');
  const [duration, setDuration] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleContinue = () => {
    if (!symptoms) {
      alert('Please describe your symptoms.');
      return;
    }
    if (!duration) {
      alert('Please select when your symptoms started.');
      return;
    }
    navigate('/gp-symptoms-worsened');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
         <img src={logo} alt="Logo" className="logo2" />
      <h1>Please describe your symptoms in detail.</h1>
      <textarea
        placeholder="Describe your symptoms"
        value={symptoms}
        onChange={(e) => setSymptoms(e.target.value)}
      />
      <h1>When did these symptoms start?</h1>
      <div
        className={`option ${duration === 'Less than 3 days' ? 'selected' : ''}`}
        onClick={() => setDuration('Less than 3 days')}
      >
        Less than 3 days
      </div>
      <div
        className={`option ${duration === '3–7 days' ? 'selected' : ''}`}
        onClick={() => setDuration('3–7 days')}
      >
        3–7 days
      </div>
      <div
        className={`option ${duration === 'Over 7 days' ? 'selected' : ''}`}
        onClick={() => setDuration('Over 7 days')}
      >
        Over 7 days
      </div>
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default GPAcuteIllness;
