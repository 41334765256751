import React from 'react';
import './WhatsAppButton.css';
import whatsappIcon from '../images/whatsApp-icon.png'; // Ensure this path is correct

const WhatsAppButton = () => {
  const phoneNumber = '+447399323620'; // Your phone number in international format without plus sign
  const message = 'I would like to enquire about a service at The Wellness. I am interested in: ';
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;

  return (
    <a
      href={whatsappLink}
      className="whatsapp-button"
      target="_blank"
      rel="noreferrer"
    >
      <img src={whatsappIcon} alt="WhatsApp" className="whatsapp-icon" />
    </a>
  );
};

export default WhatsAppButton;
