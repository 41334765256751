import React, { useState, useEffect } from 'react';
import './EnquiryPage.css';

const EnquiryPage = () => {
  const [formData, setFormData] = useState({
    treatment: '',
    name: '',
    number: '',
    email: '',
  });

  const [isVisible, setIsVisible] = useState(false); // State to trigger fade-in

  useEffect(() => {
    setIsVisible(true); // Trigger fade-in effect on component mount
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Enquiry submitted:', formData);
    // Add form submission logic here (e.g., send data to a server)
    alert('Enquiry submitted successfully!');
  };

  return (
    <div className={`page ${isVisible ? 'fade-in' : ''}`}>
      <div className="enquiry-container">
        <h1>Enquiry Form</h1>
        <form onSubmit={handleSubmit}>
          <label>
            <select
              name="treatment"
              placeholder="Select Treatment"
              value={formData.treatment}
              onChange={handleInputChange}
            >
              <option value="">Select Treatment</option>
              <option value="PRP">PRP</option>
              <option value="Private GP Appointment">Private GP Appointment</option>
              <option value="Exosomes">Exosomes</option>
              <option value="Weight Loss">Weight Loss</option>
              <option value="Therapy">Therapy</option>
              <option value="Blood Tests">Blood Tests</option>
            </select>
          </label>
          <label>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            <input
              type="text"
              name="number"
              placeholder="Phone number"
              value={formData.number}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default EnquiryPage;
