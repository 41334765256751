import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Therapy.css';
import logo from '../../../images/logo5.png';

const TherapyImpactDailyLife = () => {
  const navigate = useNavigate();
  const [impact, setImpact] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleContinue = () => {
    if (!impact) {
      alert('Please provide a rating before continuing.');
      return;
    }
    navigate('/therapy-previous-therapy');
  };

  return (
    <div className={`container ${isVisible ? 'fade-in' : ''}`}>
                         <img src={logo} alt="Logo" className="logo2" />
      <h1>How much do these symptoms impact your daily life on a scale of 1–10?</h1>
      <input
        type="number"
        min="1"
        max="10"
        value={impact}
        onChange={(e) => setImpact(e.target.value)}
        placeholder="Enter a number between 1 and 10"
      />
      <button className="button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};

export default TherapyImpactDailyLife;
